import { writable } from 'svelte/store'
import { browser } from '$app/env'
import { getAuth, onAuthStateChanged, signInWithRedirect, signOut as _signOut, GoogleAuthProvider, EmailAuthProvider, signInWithEmailAndPassword } from "firebase/auth"
import type { User } from "firebase/auth"
import { app } from './firebase'

export interface AuthState {
  user: User | null
  known: boolean
}

const createAuth = () => {
  const { subscribe, set } = writable<AuthState>({ user: null, known: false })

  async function listen() {
    const auth = getAuth(app)
    onAuthStateChanged(auth,
      user => set({ user, known: true }),
      err => console.error(err.message),
    )
  }

  if (browser) {
    // listen to auth changes on client
    listen()
  } else {
    // no auth on server in this example
    set({ user: null, known: true })
  }

  function providerFor(name: string) {
    switch (name) {
      case 'google':   return new GoogleAuthProvider()
      case 'email': return new EmailAuthProvider()
      default:         throw 'unknown provider ' + name
    }
  }

  async function signInWith(name: string, email: string, password: string) {
    return new Promise(async (resolve, reject) => {
      const auth = getAuth(app)
      const provider = providerFor(name)
      if(name === 'google'){
        await signInWithRedirect(auth, provider);
      } else {
        signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          //goto("/");
          return resolve('success');
        })
        .catch(err => {
          console.log(`Error Signing In: ${err}`)
          //goto(`/login?error=login failed`);
          return reject('Login Failed reject');
        });
          
      }
    });

  }

  async function signOut() {
    const auth = getAuth(app)
    await _signOut(auth);
  }

  return {
    subscribe,
    signInWith,
    signOut,
  }
}

export const auth = createAuth()
